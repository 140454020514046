<template>
  <div>    
    
    <div v-if="loading == true" class="container"><div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>      
    <div v-if="loading == false">
    <div class="d-flex">
      <b-form-datepicker id="example-datepicker" v-model="date" class="m-3" @input="logValue"></b-form-datepicker>          
      <User class="m-3"/>            
    </div>      
    <div class="d-flex" >
        <b-button class="m-3 align-items-center btn_bootstrap" @click="convertToSalesmen()">الزيارات</b-button>
      </div>
    <div class="px-4 mb-2">
      <v-chip-group v-model="selection" selected-class="text-deep-purple-accent-4">
        <v-chip  :color="chip == -1?'orange':''" @click="editDate(-1)">أمس</v-chip>

        <v-chip :color="chip == 0?'orange':''" @click="editDate(0)">اليوم</v-chip>

        <v-chip :color="chip == 1?'orange':''" @click="editDate(1)">الغد</v-chip>

        <v-chip :color="chip == 2?'orange':''" @click="editDate(2)">بعد الغد</v-chip>
      </v-chip-group>
    </div>
    <h1 v-if="ExistVisits == true && loading == false" class="m-4"> لا يوجد زيارات عند التاريخ المحدد ....</h1>        
    <!--<div class="data-picker">
      <v-date-picker v-model="dateToday" @input="logValue" mode="dateTime" is24hr>
    </v-date-picker>
    </div>
  class=" d-flex justify-center text-center font-weight-bold"-->
    
    <draggable
      v-if="viewType == 'card'"
        class="row"
        v-model="products"
        @end="onDropCallback"
        :sort="true"
      >            
        <v-col v-for="(item, i) in products" :key="i" cols="12" md="3">
          <v-card>            
            <v-card-title              
            >
              {{ item.Id }}
            </v-card-title>                  
          </v-card>
        </v-col>
    </draggable>
    <template>
  <v-card
    :disabled="loading"
    :loading="loading"
    class="mx-auto my-12"
    max-width="374"
  >
    <template v-slot:loader="{ isActive }">
      <v-progress-linear
        :active="isActive"
        color="deep-purple"
        height="4"
        indeterminate
      ></v-progress-linear>
    </template>
        
    <b-modal id="bv-modal-example" hide-footer>
    <template #modal-title>
      <div class="text-center">تأكيد بداية الجلسة</div>
    </template>
    <div class="d-block text-center">
      <h3>هل أنت متأكد ابتداء الجلسة</h3>
    </div>
    <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example'); editStartSession(id);">تأكيد</b-button>
    </b-modal>
    <b-modal id="bv-modal-example2" hide-footer>
    <template #modal-title>
      <div class="text-center">تأكيد انتهاء الجلسة</div>
    </template>
    <div class="d-block text-center">
      <h3>هل أنت متأكد من انتهاء الجلسة</h3>
    </div>
    <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example2'); editFinishSession(id);">تأكيد</b-button>
    </b-modal>

    <b-modal id="bv-modal-CancelSession" hide-footer>
    <template #modal-title>
      <div class="text-center">تأكيد إلغاء الجلسة</div>
    </template>
    <div class="d-block text-center">
      <h3>هل أنت متأكد من إلغاء الجلسة</h3>
    </div>
    <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-CancelSession'); editFinishSession(id);">تأكيد</b-button>
    </b-modal>
    
  </v-card>
</template>
<div >
  
  <div class="card1" v-for="(item, i) in products" :key="i"  >            
  <template >
    <v-card    
    class="mx-auto anim"
    max-width="344"
    v-if="(chip == 0 && item.IsLate == -1 && (item.IsCancelSession != 1) /*|| (item.IsLate !=0 && item.IsLate !=10 && item.finishSession == null)-*/) && item.ClientName != undefined && loading == false"
  >
  <div v-if="item.finishSession!=null" class="backend">
    <div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>   
      <v-card-title >
          <div class="wr" >
            {{ item.ClientName +' / ' + item.BranchName}} <br> {{ item.Address }}                    
          </div>        
        </v-card-title> 
    
    <div class="m-2">
      <div class="blink_me"><svg-icon  color="#1e90ff" type="mdi" :path="pathClock"></svg-icon>      
      {{ item.appointment     
      }}</div>
    <div v-if="item.IsCancelSession == 1"><svg-icon color="red" type="mdi" :path="pathmdiCancel"></svg-icon>&nbsp;ملغاة</div>
      <div v-else-if="item.startSession != null && item.IsLate2 == 0"><svg-icon color="green" type="mdi" :path="pathmdiCheckCircleOutline"></svg-icon>&nbsp;تمت عالموعد</div>
      <div v-else-if="item.IsLate2 == 1 && item.finishSession!=null"><svg-icon color="orange" type="mdi" :path="pathmdiCheckCircleOutline"></svg-icon>&nbsp;تمت متأخرة</div>                              
    </div>
    <v-divider></v-divider>    
    <v-card-subtitle v-if="admin == true" class="mr-3 font-weight-bold">
      {{' الموظف :  '+item.DisplayName }}      
    </v-card-subtitle>    
    <v-card-subtitle v-if="item.Converter!=null && item.Converter!=''" class="mr-3 font-weight-bold">
      {{ item.task + ' / '+item.Converter }}<br>{{ item.ChannelName}}
    </v-card-subtitle>    
    <v-card-subtitle v-else class="mr-3 font-weight-bold">
      {{ item.task }}<br>{{ item.ChannelName }}
    </v-card-subtitle>    
    <v-card-subtitle v-if="item.PhoneNumber != null" class="telephone font-weight-bold mr-3">
      &nbsp;&nbsp;&nbsp;<a v-bind:href="'tel:'+ item.PhoneNumber"><svg-icon type="mdi" :path="pathPhone"></svg-icon></a> &nbsp;&nbsp;&nbsp; <a class="text-md-center" v-bind:href="'https://wa.me/'+ item.PhoneNumber"><svg-icon type="mdi" :path="pathW"></svg-icon></a> {{ item.PhoneNumber+ '  '  }}
    </v-card-subtitle >    
    <v-card-subtitle v-if="item.startSession != null || item.finishSession != null" class="mr-3 font-weight-bold">      
      <svg-icon  v-if="item.startSession != null" color="green" type="mdi" :path="pathClockIn"></svg-icon> {{item.startSession!=null ? item.startSession + '&nbsp;&nbsp;&nbsp;'  :''}}
      <svg-icon v-if="item.finishSession != null" color="green" type="mdi" :path="pathClockEnd"></svg-icon> {{item.finishSession!=null ? item.finishSession + '&nbsp;&nbsp;&nbsp;'  :''}}
      <!--<div class="blink_me"><svg-icon  color="orange" type="mdi" :path="pathClock"></svg-icon>      
      {{ item.appointment     
      }}</div>    -->
    </v-card-subtitle>   
    <v-card-subtitle v-if="item.IsPaid" class="mr-3 font-weight-bold">
      <!--<input disabled type="radio" id="one" :value=true v-model="item.IsPaid" />      -->
      <label  for="one" > مأجورة :&nbsp;{{ item.cost }} &nbsp; د.ع</label>   
      <!--<label v-else for="two"> غير مأجورة  </label>-->
    </v-card-subtitle>  
    <v-card-subtitle v-if="item.IsAnydesk" class="mr-3 font-weight-bold">
      <label for="one">  عن بعد  </label>      
      <!--<label v-else for="two"> زيارة  </label>-->
    </v-card-subtitle>       
    <v-card-actions v-if="chip == 0">      
      <v-spacer></v-spacer>   
      <div class="btn">
        <!--<b-button variant="light" v-if="item.startSession != null"         
        ><svg-icon  color="green" type="mdi" :path="pathClockIn"></svg-icon></b-button>-->        
        <!--<a class="b_btn" :href="'http://localhost:5000/request?cost='+item.cost"><b-button  v-if="item.cost >= 250"  variant="light"> <svg-icon color="green" type="mdi" :path="pathmdiCash"></svg-icon></b-button></a>-->
        <b-button class="b_btn" v-if="item.cost >= 250 && item.IsPaid" @click="Payment(item.id,item.cost)"  variant="light"> <svg-icon color="green" type="mdi" :path="pathmdiCash"></svg-icon></b-button>
        <b-button v-if="chip == 0 && item.startSession==null" id="show-btn" @click="EditId(item.Id,item.appointment,item.IsLate,item.startSession); $bvModal.show('bv-modal-example') ; " variant="light" > <svg-icon color="red" type="mdi" :path="pathClockIn"></svg-icon></b-button>
        <b-button v-if="chip == 0" variant="light"  class="b_btn"      
          @click="show12 = !show12 ; showDetails[i] = !showDetails[i]"
        ><svg-icon v-if="show12" type="mdi" :path="path"></svg-icon><svg-icon v-else type="mdi" :path="pathDown"></svg-icon></b-button>
        <!--<b-button variant="light" v-if="item.finishSession != null"       
        ><svg-icon color="green" type="mdi" :path="pathClockEnd"></svg-icon></b-button>-->
        <b-button variant="light"  v-if="item.finishSession == null && (array_details[i]!=null && array_details[i]!='') && chip == 0 && item.startSession != null" @click="$bvModal.show('bv-modal-example2') ; EditId(item.Id,item.appointment,item.IsLate,item.startSession);"><svg-icon  color="red" type="mdi" :path="pathClockEnd"></svg-icon></b-button>
        <!--<b-button variant="light"  v-if="item.finishSession == null && chip == 0 && item.startSession != null" @click="$bvModal.show('bv-modal-example2') ; EditId(item.Id);"><svg-icon  color="red" type="mdi" :path="pathClockEnd"></svg-icon></b-button>-->
        <!--<b-button variant="light"  v-else-if="chip == 0" ><svg-icon  color="red" type="mdi" :path="pathClockEnd"></svg-icon></b-button>-->
      </div>               
    </v-card-actions>
    <v-card-subtitle v-else class="mr-3 font-weight-bold">
      {{ item.details }}
    </v-card-subtitle>
    <v-expand-transition>
      <div v-if="showDetails[i]">
        <v-divider></v-divider>
        <v-textarea class="ml-3 mr-3 pa-0" outlined label="التفاصيل" name="details" v-model="item.details"
                  :rules="[$rules.required()]" width ="500px"></v-textarea>
          <div class="text-center">
            <v-btn v-if="chip==0 && item.details != ''" class="col-5 m-2" color="primary" @click="editDetails(item.Id,item.details,i)">تم</v-btn>
            <v-btn v-if="item.IsCancelSession==false && chip==0 && item.details != ''" class="col-5" color="error" @click="editCancelSession(item.Id,item.details,item.CountHourSession,item.indexAppointment,item.UserId,item.TIMESTAMP,i)">إلغاء الزيارة</v-btn>
          </div>        
      </div>     
    </v-expand-transition>
    </div>
  </div>
    <div v-else>
      <div>
      <span></span>
      <span></span>
      <span></span>
      <span></span>   
      <v-card-title >
          <div class="wr" >
            {{ item.ClientName +' / ' + item.BranchName}} <br> {{ item.Address }}                    
          </div>        
        </v-card-title> 
    
    <div class="m-2">
      <div class="blink_me"><svg-icon  color="#1e90ff" type="mdi" :path="pathClock"></svg-icon>      
      {{ item.appointment     
      }}</div>
    <div v-if="item.IsCancelSession == 1"><svg-icon color="red" type="mdi" :path="pathmdiCancel"></svg-icon>&nbsp;ملغاة</div>
      <div v-else-if="item.startSession != null && item.IsLate2 == 0"><svg-icon color="green" type="mdi" :path="pathmdiCheckCircleOutline"></svg-icon>&nbsp;تمت عالموعد</div>
      <div v-else-if="item.IsLate2 == 1 && item.finishSession!=null"><svg-icon color="orange" type="mdi" :path="pathmdiCheckCircleOutline"></svg-icon>&nbsp;تمت متأخرة</div>                              
    </div>
    <v-divider></v-divider>    
    <v-card-subtitle v-if="admin == true" class="mr-3 font-weight-bold">
      {{' الموظف :  '+item.DisplayName }}      
    </v-card-subtitle>    
    <v-card-subtitle v-if="item.Converter!=null && item.Converter!=''" class="mr-3 font-weight-bold">
      {{ item.task + ' / '+item.Converter }}<br>{{ item.ChannelName}}
    </v-card-subtitle>    
    <v-card-subtitle v-else class="mr-3 font-weight-bold">
      {{ item.task }}<br>{{ item.ChannelName }}
    </v-card-subtitle>    
    <v-card-subtitle v-if="item.PhoneNumber != null" class="telephone font-weight-bold mr-3">
      &nbsp;&nbsp;&nbsp;<a v-bind:href="'tel:'+ item.PhoneNumber"><svg-icon type="mdi" :path="pathPhone"></svg-icon></a> &nbsp;&nbsp;&nbsp; <a class="text-md-center" v-bind:href="'https://wa.me/'+ item.PhoneNumber"><svg-icon type="mdi" :path="pathW"></svg-icon></a> {{ item.PhoneNumber+ '  '  }}
    </v-card-subtitle >    
    <v-card-subtitle v-if="item.startSession != null || item.finishSession != null" class="mr-3 font-weight-bold">      
      <svg-icon  v-if="item.startSession != null" color="green" type="mdi" :path="pathClockIn"></svg-icon> {{item.startSession!=null ? item.startSession + '&nbsp;&nbsp;&nbsp;'  :''}}
      <svg-icon v-if="item.finishSession != null" color="green" type="mdi" :path="pathClockEnd"></svg-icon> {{item.finishSession!=null ? item.finishSession + '&nbsp;&nbsp;&nbsp;'  :''}}
      <!--<div class="blink_me"><svg-icon  color="orange" type="mdi" :path="pathClock"></svg-icon>      
      {{ item.appointment     
      }}</div>    -->
    </v-card-subtitle>   
    <v-card-subtitle v-if="item.IsPaid" class="mr-3 font-weight-bold">
      <!--<input disabled type="radio" id="one" :value=true v-model="item.IsPaid" />      -->
      <label  for="one" > مأجورة :&nbsp;{{ item.cost }} &nbsp; د.ع</label>   
      <!--<label v-else for="two"> غير مأجورة  </label>-->
    </v-card-subtitle>  
    <v-card-subtitle v-if="item.IsAnydesk" class="mr-3 font-weight-bold">
      <label for="one">  عن بعد  </label>      
      <!--<label v-else for="two"> زيارة  </label>-->
    </v-card-subtitle>       
    <v-card-actions v-if="chip == 0">      
      <v-spacer></v-spacer>   
      <div class="btn">
        <!--<b-button variant="light" v-if="item.startSession != null"         
        ><svg-icon  color="green" type="mdi" :path="pathClockIn"></svg-icon></b-button>-->        
        <!--<a class="b_btn" :href="'http://localhost:5000/request?cost='+item.cost"><b-button  v-if="item.cost >= 250"  variant="light"> <svg-icon color="green" type="mdi" :path="pathmdiCash"></svg-icon></b-button></a>-->
        <b-button class="b_btn" v-if="item.cost >= 250 && item.IsPaid" @click="Payment(item.id,item.cost)"  variant="light"> <svg-icon color="green" type="mdi" :path="pathmdiCash"></svg-icon></b-button>
        <b-button v-if="chip == 0 && item.startSession==null" id="show-btn" @click="EditId(item.Id,item.appointment,item.IsLate,item.startSession); $bvModal.show('bv-modal-example') ; " variant="light" > <svg-icon color="red" type="mdi" :path="pathClockIn"></svg-icon></b-button>
        <b-button v-if="chip == 0" variant="light"  class="b_btn"      
          @click="show12 = !show12 ; showDetails[i] = !showDetails[i]"
        ><svg-icon v-if="show12" type="mdi" :path="path"></svg-icon><svg-icon v-else type="mdi" :path="pathDown"></svg-icon></b-button>
        <!--<b-button variant="light" v-if="item.finishSession != null"       
        ><svg-icon color="green" type="mdi" :path="pathClockEnd"></svg-icon></b-button>-->
        <b-button variant="light"  v-if="item.finishSession == null && (array_details[i]!=null && array_details[i]!='') && chip == 0 && item.startSession != null" @click="$bvModal.show('bv-modal-example2') ; EditId(item.Id,item.appointment,item.IsLate,item.startSession);"><svg-icon  color="red" type="mdi" :path="pathClockEnd"></svg-icon></b-button>
        <!--<b-button variant="light"  v-if="item.finishSession == null && chip == 0 && item.startSession != null" @click="$bvModal.show('bv-modal-example2') ; EditId(item.Id);"><svg-icon  color="red" type="mdi" :path="pathClockEnd"></svg-icon></b-button>-->
        <!--<b-button variant="light"  v-else-if="chip == 0" ><svg-icon  color="red" type="mdi" :path="pathClockEnd"></svg-icon></b-button>-->
      </div>               
    </v-card-actions>
    <v-card-subtitle v-else class="mr-3 font-weight-bold">
      {{ item.details }}
    </v-card-subtitle>
    <v-expand-transition>
      <div v-if="showDetails[i]">
        <v-divider></v-divider>
        <v-textarea class="ml-3 mr-3 pa-0" outlined label="التفاصيل" name="details" v-model="item.details"
                  :rules="[$rules.required()]" width ="500px"></v-textarea>
          <div class="text-center">
            <v-btn v-if="chip==0 && item.details != ''" class="col-5 m-2" color="primary" @click="editDetails(item.Id,item.details,i)">تم</v-btn>
            <v-btn v-if="item.IsCancelSession==false && chip==0 && item.details != ''" class="col-5" color="error" @click="editCancelSession(item.Id,item.details,item.CountHourSession,item.indexAppointment,item.UserId,item.TIMESTAMP,i)">إلغاء الزيارة</v-btn>
          </div>        
      </div>     
    </v-expand-transition>
    </div>
    </div>
    </v-card>
    <v-card
      class="mx-auto"
      max-width="344"    
      v-if="/*item.IsLate == 10 || item.IsLate != 0 && item.finishSession != null*/(chip != 0 || item.IsLate != -1 || item.IsCancelSession == 1) && item.ClientName != undefined && loading == false"
    >
      <div>
        <div v-if="item.finishSession!=null" class="backend">
        
        <v-card-title >
          <div class="wr">
            {{ item.ClientName +' / ' + item.BranchName}} <br> {{ item.Address }}                    
          </div>        
        </v-card-title> 
    
      <div class="m-2" >
        <div><svg-icon  color="#1e90ff" type="mdi" :path="pathClock"></svg-icon>      
        {{ item.appointment     
        }}</div>
        <div v-if="item.IsCancelSession == 1"><svg-icon color="red" type="mdi" :path="pathmdiCancel"></svg-icon>&nbsp;ملغاة</div>
        <div v-else-if="item.startSession != null && item.IsLate2 == 0"><svg-icon color="green" type="mdi" :path="pathmdiCheckCircleOutline"></svg-icon>&nbsp;تمت عالموعد</div>
        <div v-else-if="item.IsLate2 == 1 && item.finishSession!=null"><svg-icon color="orange" type="mdi" :path="pathmdiCheckCircleOutline"></svg-icon>&nbsp;تمت متأخرة</div>    
      </div>    
      <v-divider></v-divider>    
      <v-card-subtitle v-if="admin == true" class="mr-3 font-weight-bold">
        {{' الموظف :  '+item.DisplayName }}      
      </v-card-subtitle>    
      <v-card-subtitle v-if="item.Converter!=null && item.Converter!=''" class="mr-3 font-weight-bold">
        {{ item.task + ' / '+item.Converter }}<br>{{ item.ChannelName}}
      </v-card-subtitle>    
      <v-card-subtitle v-else class="mr-3 font-weight-bold">
        {{ item.task }}<br>{{ item.ChannelName }}
      </v-card-subtitle>    
      <v-card-subtitle v-if="item.PhoneNumber != null" class="telephone font-weight-bold mr-3">
        &nbsp;&nbsp;&nbsp;<a v-bind:href="'tel:'+ item.PhoneNumber"><svg-icon type="mdi" :path="pathPhone"></svg-icon></a> &nbsp;&nbsp;&nbsp; <a class="text-md-center" v-bind:href="'https://wa.me/'+ item.PhoneNumber"><svg-icon type="mdi" :path="pathW"></svg-icon></a> {{ item.PhoneNumber+ '  '  }}
      </v-card-subtitle >    
      <v-card-subtitle v-if="item.startSession != null || item.finishSession != null" class="mr-3 font-weight-bold">      
        <svg-icon  v-if="item.startSession != null" color="green" type="mdi" :path="pathClockIn"></svg-icon> {{item.startSession!=null ? item.startSession + '&nbsp;&nbsp;&nbsp;'  :''}}
        <svg-icon v-if="item.finishSession != null" color="green" type="mdi" :path="pathClockEnd"></svg-icon> {{item.finishSession!=null ? item.finishSession + '&nbsp;&nbsp;&nbsp;'  :''}}
      <!-- <svg-icon  color="orange" type="mdi" :path="pathClock"></svg-icon>
        {{ item.appointment     
        }}&nbsp;&nbsp;&nbsp; -->     
      </v-card-subtitle>   
      <v-card-subtitle v-if="item.IsPaid"  class="mr-3 font-weight-bold">
        <!--<input disabled type="radio" id="one" :value=true v-model="item.IsPaid" />   -->   
        <label for="one" >  مأجورة :&nbsp;{{ item.cost }} &nbsp; د.ع</label>   
        <!--<label v-else for="two" >غير مأجورة </label>-->
      </v-card-subtitle>  
      <v-card-subtitle v-if="item.IsAnydesk"  class="mr-3 font-weight-bold">
        <label  for="one">  عن بعد  </label>      
        <!--<label v-else for="two"> زيارة </label>-->
      </v-card-subtitle>       
      <v-card-actions v-if="chip == 0  && item.IsCancelSession == 0">      
        <v-spacer></v-spacer>   
        <div class="btn">
          <!--<b-button variant="light" v-if="item.startSession != null"         
          ><svg-icon  color="green" type="mdi" :path="pathClockIn"></svg-icon></b-button>-->        
          <!--<a class="b_btn" :href="'http://localhost:5000/request?cost='+item.cost"><b-button v-if="item.cost >= 250"  variant="light"> <svg-icon color="green" type="mdi" :path="pathmdiCash"></svg-icon></b-button></a>-->
          <b-button class="b_btn"  v-if="item.cost >= 250 && item.IsPaid"  variant="light" @click="Payment(item.id,item.cost)"> <svg-icon color="green" type="mdi" :path="pathmdiCash"></svg-icon></b-button>
          <b-button v-if="chip == 0 && item.startSession==null" id="show-btn" @click="EditId(item.Id,item.appointment,item.IsLate,item.startSession); $bvModal.show('bv-modal-example') ; " variant="light" > <svg-icon color="red" type="mdi" :path="pathClockIn"></svg-icon></b-button>
          <b-button v-if="chip == 0" variant="light"  class="b_btn"      
            @click="show12 = !show12 ; showDetails[i] = !showDetails[i]"
          ><svg-icon v-if="show12" type="mdi" :path="path"></svg-icon><svg-icon v-else type="mdi" :path="pathDown"></svg-icon></b-button>
          <!--<b-button variant="light" v-if="item.finishSession != null"       
          ><svg-icon color="green" type="mdi" :path="pathClockEnd"></svg-icon></b-button>-->
          <b-button variant="light"  v-if="item.finishSession == null && (array_details[i]!=null && array_details[i]!='') && chip == 0 && item.startSession != null" @click="$bvModal.show('bv-modal-example2') ; EditId(item.Id,item.appointment,item.IsLate,item.startSession);"><svg-icon  color="red" type="mdi" :path="pathClockEnd"></svg-icon></b-button>
          <!--<b-button variant="light"  v-if="item.finishSession == null && chip == 0 && item.startSession != null" @click="$bvModal.show('bv-modal-example2') ; EditId(item.Id);"><svg-icon  color="red" type="mdi" :path="pathClockEnd"></svg-icon></b-button>-->
          <!--<b-button variant="light"  v-else-if="chip == 0" ><svg-icon  color="red" type="mdi" :path="pathClockEnd"></svg-icon></b-button>-->
        </div>               
      </v-card-actions>
      <v-card-subtitle v-else class="mr-3 font-weight-bold">
        {{ item.details }}
      </v-card-subtitle>
      <v-expand-transition>
        <div v-if="showDetails[i]">
          <v-divider></v-divider>
          <v-textarea class="ml-3 mr-3 pa-0" outlined label="التفاصيل" name="details" v-model="item.details"
                    :rules="[$rules.required()]" width ="500px"></v-textarea>
            <div class="text-center">
              <v-btn v-if="chip==0 && item.details != ''" class="col-5 m-2" color="primary" @click="editDetails(item.Id,item.details,i)">تم</v-btn>
              <v-btn v-if="item.IsCancelSession==false && chip==0 && item.details != ''" class="col-5" color="error" @click="editCancelSession(item.Id,item.details,item.CountHourSession,item.indexAppointment,item.UserId,item.TIMESTAMP,i)">إلغاء الزيارة</v-btn>
            </div>        
        </div>     
      </v-expand-transition>
      </div>
      <div v-else>
        
        <v-card-title >
          <div class="wr">
            {{ item.ClientName +' / ' + item.BranchName}} <br> {{ item.Address }}                    
          </div>        
        </v-card-title> 
    
      <div class="m-2" >
        <div><svg-icon  color="#1e90ff" type="mdi" :path="pathClock"></svg-icon>      
        {{ item.appointment     
        }}</div>
        <div v-if="item.IsCancelSession == 1"><svg-icon color="red" type="mdi" :path="pathmdiCancel"></svg-icon>&nbsp;ملغاة</div>
        <div v-else-if="item.startSession != null && item.IsLate2 == 0"><svg-icon color="green" type="mdi" :path="pathmdiCheckCircleOutline"></svg-icon>&nbsp;تمت عالموعد</div>
        <div v-else-if="item.IsLate2 == 1 && item.finishSession!=null"><svg-icon color="orange" type="mdi" :path="pathmdiCheckCircleOutline"></svg-icon>&nbsp;تمت متأخرة</div>    
      </div>    
      <v-divider></v-divider>    
      <v-card-subtitle v-if="admin == true" class="mr-3 font-weight-bold">
        {{' الموظف :  '+item.DisplayName }}      
      </v-card-subtitle>    
      <v-card-subtitle v-if="item.Converter!=null && item.Converter!=''" class="mr-3 font-weight-bold">
        {{ item.task + ' / '+item.Converter }}<br>{{ item.ChannelName}}
      </v-card-subtitle>    
      <v-card-subtitle v-else class="mr-3 font-weight-bold">
        {{ item.task }}<br>{{ item.ChannelName }}
      </v-card-subtitle>    
      <v-card-subtitle v-if="item.PhoneNumber != null" class="telephone font-weight-bold mr-3">
        &nbsp;&nbsp;&nbsp;<a v-bind:href="'tel:'+ item.PhoneNumber"><svg-icon type="mdi" :path="pathPhone"></svg-icon></a> &nbsp;&nbsp;&nbsp; <a class="text-md-center" v-bind:href="'https://wa.me/'+ item.PhoneNumber"><svg-icon type="mdi" :path="pathW"></svg-icon></a> {{ item.PhoneNumber+ '  '  }}
      </v-card-subtitle >    
      <v-card-subtitle v-if="item.startSession != null || item.finishSession != null" class="mr-3 font-weight-bold">      
        <svg-icon  v-if="item.startSession != null" color="green" type="mdi" :path="pathClockIn"></svg-icon> {{item.startSession!=null ? item.startSession + '&nbsp;&nbsp;&nbsp;'  :''}}
        <svg-icon v-if="item.finishSession != null" color="green" type="mdi" :path="pathClockEnd"></svg-icon> {{item.finishSession!=null ? item.finishSession + '&nbsp;&nbsp;&nbsp;'  :''}}
      <!-- <svg-icon  color="orange" type="mdi" :path="pathClock"></svg-icon>
        {{ item.appointment     
        }}&nbsp;&nbsp;&nbsp; -->     
      </v-card-subtitle>   
      <v-card-subtitle v-if="item.IsPaid"  class="mr-3 font-weight-bold">
        <!--<input disabled type="radio" id="one" :value=true v-model="item.IsPaid" />   -->   
        <label for="one" >  مأجورة :&nbsp;{{ item.cost }} &nbsp; د.ع</label>   
        <!--<label v-else for="two" >غير مأجورة </label>-->
      </v-card-subtitle>  
      <v-card-subtitle v-if="item.IsAnydesk"  class="mr-3 font-weight-bold">
        <label  for="one">  عن بعد  </label>      
        <!--<label v-else for="two"> زيارة </label>-->
      </v-card-subtitle>       
      <v-card-actions v-if="chip == 0  && item.IsCancelSession == 0">      
        <v-spacer></v-spacer>   
        <div class="btn">
          <!--<b-button variant="light" v-if="item.startSession != null"         
          ><svg-icon  color="green" type="mdi" :path="pathClockIn"></svg-icon></b-button>-->        
          <!--<a class="b_btn" :href="'http://localhost:5000/request?cost='+item.cost"><b-button v-if="item.cost >= 250"  variant="light"> <svg-icon color="green" type="mdi" :path="pathmdiCash"></svg-icon></b-button></a>-->
          <b-button class="b_btn"  v-if="item.cost >= 250 && item.IsPaid"  variant="light" @click="Payment(item.id,item.cost)"> <svg-icon color="green" type="mdi" :path="pathmdiCash"></svg-icon></b-button>
          <b-button v-if="chip == 0 && item.startSession==null" id="show-btn" @click="EditId(item.Id,item.appointment,item.IsLate,item.startSession); $bvModal.show('bv-modal-example') ; " variant="light" > <svg-icon color="red" type="mdi" :path="pathClockIn"></svg-icon></b-button>
          <b-button v-if="chip == 0" variant="light"  class="b_btn"      
            @click="show12 = !show12 ; showDetails[i] = !showDetails[i]"
          ><svg-icon v-if="show12" type="mdi" :path="path"></svg-icon><svg-icon v-else type="mdi" :path="pathDown"></svg-icon></b-button>
          <!--<b-button variant="light" v-if="item.finishSession != null"       
          ><svg-icon color="green" type="mdi" :path="pathClockEnd"></svg-icon></b-button>-->
          <b-button variant="light"  v-if="item.finishSession == null && (array_details[i]!=null && array_details[i]!='') && chip == 0 && item.startSession != null" @click="$bvModal.show('bv-modal-example2') ; EditId(item.Id,item.appointment,item.IsLate,item.startSession);"><svg-icon  color="red" type="mdi" :path="pathClockEnd"></svg-icon></b-button>
          <!--<b-button variant="light"  v-if="item.finishSession == null && chip == 0 && item.startSession != null" @click="$bvModal.show('bv-modal-example2') ; EditId(item.Id);"><svg-icon  color="red" type="mdi" :path="pathClockEnd"></svg-icon></b-button>-->
          <!--<b-button variant="light"  v-else-if="chip == 0" ><svg-icon  color="red" type="mdi" :path="pathClockEnd"></svg-icon></b-button>-->
        </div>               
      </v-card-actions>
      <v-card-subtitle v-else class="mr-3 font-weight-bold">
        {{ item.details }}
      </v-card-subtitle>
      <v-expand-transition>
        <div v-if="showDetails[i]">
          <v-divider></v-divider>
          <v-textarea class="ml-3 mr-3 pa-0" outlined label="التفاصيل" name="details" v-model="item.details"
                    :rules="[$rules.required()]" width ="500px"></v-textarea>
            <div class="text-center">
              <v-btn v-if="chip==0 && item.details != ''" class="col-5 m-2" color="primary" @click="editDetails(item.Id,item.details,i)">تم</v-btn>
              <v-btn v-if="item.IsCancelSession==false && chip==0 && item.details != ''" class="col-5" color="error" @click="editCancelSession(item.Id,item.details,item.CountHourSession,item.indexAppointment,item.UserId,item.TIMESTAMP,i)">إلغاء الزيارة</v-btn>
            </div>        
        </div>     
      </v-expand-transition>
      </div>
      </div>
    </v-card>
</template>
    </div>
</div>
              <!--<v-data-table
              v-if="viewType == 'table'"
                :headers="headers"
                :ripple="false"
                :items="products"
                disable-pagination
                hide-default-footer
                :loading="$store.state.loading"
                no-data-text="لا يوجد"
                loading-text="جار التحميل..."
              >
                <template #body="props">
                  <draggable
                    :list="props.items"
                    tag="tbody"
                    @end="onDropCallback"
                  >
                    <DraggableTable
                      v-for="(item, index) in props.items"
                      :key="index"
                      :item="item"
                      :headers="headers"
                      item-class=""
                    >
                    <template v-slot:item.actions="{ item }">
                    
                        <v-btn
                          color="primary"
                          @click="openEditDialog(item)"
                          outlined
                        >
                          <v-icon>mdi-pencil</v-icon>
                          تعديل
                        </v-btn>   
                    </template>
                      <template #item.Id="{item}">
                        {{ item.Id }}
                      </template>
                      <template v-slot:item.actions="{ item }">
                      <div class="d-flex align-center">
                        <v-btn
                          color="primary"
                          @click="openEditDialog(item)"
                          outlined
                        >
                          <v-icon>mdi-pencil</v-icon>
                          تعديل
                        </v-btn>                        
                      </div>
                    </template>
                    </DraggableTable>
                  </draggable>
                </template>
               
              </v-data-table>   -->
              <Add/>                            
  </div>
</div>
</template>

<script>
import CryptoJS from 'crypto-js';
import Add from "./Add.vue";
import draggable from "vuedraggable";
import SvgIcon from '@jamescoyle/vue-icon';
import User from "../../layouts/User.vue";
import { mdiChevronDown ,mdiChevronUp,mdiWhatsapp,mdiPhone,mdiClockIn,mdiClockEnd ,mdiClockCheckOutline ,mdiCheckCircleOutline,mdiCancel,mdiCash,mdiCheckboxMarkedCircleOutline  } from '@mdi/js';

//import DraggableTable from "../components/DraggableTable.vue";
export default {
  components: {
    draggable,
    SvgIcon,
    User,
  //  DraggableTable,
    Add
  },
  data() {
    return {
      //CryptoJS:new CryptoJS(),
      toggle:"visits",
      startSession:"",
      IsLate:0,
      appointment:"",
      loading:false,
      admin:false,
      array_details:[],
      ExistVisits:false,
      details:"",
      chip:0,
      id:"",
      date: new Date(),
      selection: 1,
      pathmdiCheckboxMarkedCircleOutline:mdiCheckboxMarkedCircleOutline,
      path: mdiChevronUp,
      pathDown: mdiChevronDown,
      pathW:mdiWhatsapp,
      pathPhone:mdiPhone ,
      pathClockIn:mdiClockIn ,
      pathClockEnd:mdiClockEnd ,
      pathClock:mdiClockCheckOutline ,
      pathmdiCheckCircleOutline:mdiCheckCircleOutline,
      pathmdiCash:mdiCash ,
      //pathmdiCloseCircleOutline:mdiCloseCircleOutline,
      pathmdiCancel:mdiCancel,
      show12: false,
      showDetails:[],
      products: [{        
      }],      
      dateToday:"",
      dialog: false,
      viewType:'table',
      toDelete: null,
      total: 1,      
      headers: [                        
        { text: "المستخدم", value: "UserName" },
        { text: "الشركة", value: "ClientName" },        
        { text: "الفرع", value: "BranchName" },  
        { text: "رقم الهاتف", value: "PhoneNumber" },     
        { text: "القناة", value: "ChannelName" },              
        { text: "المهمة", value: "task" },               
        { text: "التفاصيل", value: "details" },    
        { text: "موعد الزيارة", value: "appointment" },          
        { text: "بداية الزيارة", value: "startSession"}, 
        { text: "نهاية الزيارة", value: "finishSession" },         
        { text: "الاجراءات", value: "actions" }, 
      ],
      options: {
        sort: "index:asc",       
        populate: "*",
        pagination: {
          page: 1,
          pageSize: 10,
        },
      },
    };
  },
  async mounted(){       
    this.$root.$on("refresh-data", () => {
      this.getnews();      
    });
  },
  async created() {         
   this.getnews();    
    this.$root.$on("refresh",async () => {
      this.getnews();
    });
    /*let categories = await this.$http.get("/categories", {
      params: {
        filters: {
          resturant: {
            id: { $eq: this.$store.state.resturant.id },
          },
        },
        pagination: {
          page: 1,
          pageSize: 100,
        },
      },
      paramsSerializer: this.$service.qsParams,
    });
    this.categories = categories.data.data;
    this.options.filters.category.id.$eq = this.categories[0].id;*/
  },
  methods: {
    convertToSalesmen(){
      this.$router.push({ name: "salesmen" });
      localStorage.setItem("page","salesmen");
    },    
    async Payment(id,pay){
      this.loading = true;
      await this.$http.get("/pay",{
        params: {
          cost:pay
        }})
      .then( (res) => {  
        var url = res.data;
        window.location.href = url;
      })          
      this.loading = false;
      
    } ,
    encrypteData(data) {
      if (data) {          
        let key = CryptoJS.enc.Utf8.parse("edariworkmenkmen");  
        const data_encrypted = CryptoJS.AES.encrypt(data, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7}).toString();
        //const iv = CryptoJS.enc.Utf8.parse("keyValue"); // Convert string to WordArray
        //const encrypted = CryptoJS.AES.encrypt(data, key, { iv: iv, mode: CryptoJS.mode.CBC });
        return data_encrypted;//" encrypted.ciphertext.toString(CryptoJS.enc.Hex);
      }
    },
    EditId(id,appointment,IsLate,startSession){
      this.id = id;
      this.appointment = appointment;
      this.IsLate = IsLate;
      this.startSession = startSession;
    },
    showitem(i){
      return this.showDetails[i];
    },
    async editDetails(id,details,i){
      this.loading = true;
      await this.$http.post("/reportEditDetails",{
            id:this.encrypteData(id.toString()),
            details:details,
          })
          .then(async (res) => {             
            if (res.status == 200) {      
              this.$store.commit("setLoading", false);     
              this.array_details[i] = details;                                                  
            } else {
              this.error = true;
              this.$store.commit("setLoading", false);
            }
          })
          this.show12 = false;
          this.$store.state.dialog = false;
          this.$root.$emit("refresh-data");
          this.loading = false;
    },
    async editCancelSession(id,details,CountHourSession,indexAppointment,UserId,TIMESTAMP,i){
      /*if(IsCancelSession == false)
      IsCancelSession=1;
      else
      IsCancelSession=0;*/
      this.loading = true;
      await this.$http.post("/reportEditCancelSession",{
            id:this.encrypteData(id.toString()),
            IsCancelSession:1,
            details:details,
            CountHourSession:CountHourSession,
            indexAppointment:indexAppointment,
            UserId:UserId,
            TIMESTAMP:TIMESTAMP
          })
          .then(async (res) => {             
            if (res.status == 200) {      
              this.$store.commit("setLoading", false);       
              this.array_details[i] = details;                                                
            } else {
              this.error = true;
              this.$store.commit("setLoading", false);
            }
          })
          this.show12 = false;
          this.$store.state.dialog = false;
          this.$root.$emit("refresh-data");
          this.loading = false;
    },
    async editStartSession(id){
      const success = async (position) => {
            const latitude  = position.coords.latitude;
            const longitude = position.coords.longitude;
            
      await this.$http.post("/reportEditStartTime",{
            id:this.encrypteData(id.toString()),
            startSession:new Date().toLocaleTimeString("en-US", {timeZone: 'Asia/Baghdad'}),
            appointment:this.appointment,
            latitude : latitude,
            longitude : longitude
          })
          .then(async (res) => {             
            if (res.status == 200) {       
              this.$store.commit("setLoading", false);                                                       
            } else {
              this.error = true;
              this.$store.commit("setLoading", false);
            }
          })
          this.$store.state.dialog = false;
          this.$root.$emit("refresh-data");
          this.loading = false;
            // Do something with the position
        };

        const error = async () => {
          await this.$http.post("/reportEditStartTime",{
            id:this.encrypteData(id.toString()),
            startSession:new Date().toLocaleTimeString("en-US", {timeZone: 'Asia/Baghdad'}),
            appointment:this.appointment,
            latitude : null,
            longitude : null
          })
          .then(async (res) => {             
            if (res.status == 200) {       
              this.$store.commit("setLoading", false);                                                       
            } else {
              this.error = true;
              this.$store.commit("setLoading", false);
            }
          })
          this.$store.state.dialog = false;
          this.$root.$emit("refresh-data");
          this.loading = false;
        };
        this.loading = true;
        // This will open permission popup
        navigator.geolocation.getCurrentPosition(success, error);
      
    },
    async editFinishSession(id){
      this.loading = true;
      await this.$http.post("/reportEditFinishTime",{
            id:this.encrypteData(id.toString()),
            finishSession:new Date().toLocaleTimeString("en-US", {timeZone: 'Asia/Baghdad'}),
            IsLate:this.IsLate,
            startSession:this.startSession,
            appointment:this.appointment,
          })
          .then(async (res) => {             
            if (res.status == 200) {      
              this.$store.commit("setLoading", false);                                                       
            } else {
              this.error = true;
              this.$store.commit("setLoading", false);
            }
          })
          this.$store.state.dialog = false;
          this.$root.$emit("refresh-data");
          this.loading = false;
    },
    editDate(d){      
      this.chip = d;
      const date = new Date();
      date.setDate(date.getDate()+d)
      let day = date.getDate();      
      let month = date.getMonth()+1;
      if(month<10)
      month = '0'+month;
      if(day<10)
      day = '0'+day;
      let year = date.getFullYear();
      this.date = `${year}-${month}-${day}`;
      this.logValue();
    },
    editShow(i){
      this.showDetails[i] = !this.showDetails[i];
    },
    async logValue(){
      this.loading = true;
      var date = new Date();
      date.setDate(date.getDate()+0)
      let day = date.getDate();      
      let month = date.getMonth()+1;
      if(month<10)
      month = '0'+month;
      if(day<10)
      day = '0'+day;
      let year = date.getFullYear();
      
      if(this.date == `${year}-${month}-${day}`)
        this.chip = 0;
      else{
        date = new Date();
        date.setDate(date.getDate()-1)
        day = date.getDate();      
        month = date.getMonth()+1;
        if(month<10)
        month = '0'+month;
        if(day<10)
        day = '0'+day;
        year = date.getFullYear();
        if(this.date == `${year}-${month}-${day}`)
        this.chip = -1;
        else{
          date = new Date();
          date.setDate(date.getDate()+1)
          day = date.getDate();      
          month = date.getMonth()+1;
          if(month<10)
          month = '0'+month;
          if(day<10)
          day = '0'+day;
          year = date.getFullYear();
          if(this.date == `${year}-${month}-${day}`)
          this.chip = 1;
          else{
            date = new Date();
            date.setDate(date.getDate()+2)
            day = date.getDate();      
            month = date.getMonth()+1;
            if(month<10)
            month = '0'+month;
            if(day<10)
            day = '0'+day;
            year = date.getFullYear();
            if(this.date == `${year}-${month}-${day}`)
            this.chip = 2;
            else
            this.chip = 5;
          }
        }
      }
      this.$store.commit("setLoading", true); 
      this.showDetails=[];
      this.array_details = [];
      var t = new Date();
      t = t.getTime().toString();   
      
        await this.$http.get("/visits",{
          params: {
            date:this.encrypteData(this.date) ,
            id:this.encrypteData(localStorage.getItem("userData")) ,
            tocken:this.encrypteData(t)                                                                     
          }})
          .then( (res) => {             
            if (res.status == 200) {                                          
              this.total = 410;     
              this.products = res.data.result;
              this.admin = res.data.admin;
                //this.products = res.data.recordsets[0];
                if(this.products.length == 0)
                  this.ExistVisits = true;
                else
                  this.ExistVisits = false;
                var d1 = new Date();
                d1 = d1.getHours();
                  for (let i = 0; i < this.products.length; i++) {
                    var c = new Date(this.products[i].timedate.toString())
                    var cc = c.getUTCHours();
                    if(parseInt(d1) == parseInt(cc)){
                      if(this.products[i].IsLate == 1)
                        this.products[i].IsLate = 0;
                    }
                    if(this.products[i].PhoneNumber != null)                    
                    this.products[i].PhoneNumber = this.products[i].PhoneNumber.replace('0','+964');                    
                    this.showDetails.push(false);
                    this.array_details.push(this.products[i].details);
                }
                this.error = false;   
                this.$store.commit("setLoading", false);     
            } else {
              this.error = true;
              this.$store.commit("setLoading", false);
            }
          })  
          this.loading = false;        
    },
    openDeleteItemDialog(item) {
      this.dialog = true;
      this.toDelete = item;
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    onDropCallback(/*evt, originalEvent*/) {
      /*console.log("onDropCallback", evt, originalEvent);
      this.products = this.array_move(
        this.products,
        evt.oldIndex,
        evt.newIndex
      );
      // this.$forceUpdate();
      let ids = this.products.map((item, index) => {
        //return { id: item.id, title: item.title, index: index };
        return { id: item.Id, title: item.Id, index: index };
      });

      this.updateIndex(ids);
      this.$root.$forceUpdate();
      this.$forceUpdate();*/
    },
    async toggleFavorite(item) {

      await this.$http.put("/products/" + item.id, {
        data: {
          favorite: item.favorite,
        },
      });
    },
    async toggleActive(id, isActive) {
      await this.$http.put("/products/" + id, {
        data: {
          isActive: !isActive,
        },
      });
      this.getnews();
    },
    async updateCategory(item) {
      if (item.category != null) {
        await this.$http.put("/products/" + item.id, {
          data: {
            category: item.category,
          },
        });
      }
    },
    async getnews() {    
      try {

           //this.$http.get("http://ticketverse-api.ft.iq/")
           this.$http.get("http://ticketverse1-api.ft.iq/")
          .then(async (res) => {             
            if (res.status == 200) {      
              //this.$store.commit("setLoading", false);     
              //this.array_details[i] = details;                                                  
            } else {
              //this.error = true;
              //this.$store.commit("setLoading", false);
            }
          })
        } catch{/**/ }  
        if(localStorage.getItem("page") == "salesmen"){
          this.$router.push({ name: "salesmen" });
          return;
        }
          
        this.loading = true;
      const date = new Date();
      let day = date.getDate();      
      let month = date.getMonth() + 1;
      if(month<10)
      month = '0'+month;
      if(day<10)
      day = '0'+day;
      let year = date.getFullYear();
      let dT = `${year}-${month}-${day}`;
      this.dateToday = dT;
      this.$store.commit("setLoading", true);
      this.showDetails=[];      
      var t = new Date();
      t = t.getTime().toString();
        await this.$http.get("/visits",{
          params: {
            date:this.encrypteData(this.dateToday), 
            id:this.encrypteData(localStorage.getItem("userData")) ,
            tocken:this.encrypteData(t)                                                    
          }})          
          /*.post("/auth/local", {
            identifier: this.form.identifier,
            password: this.form.password,
          })*/
          .then( (res) => {             
            if (res.status == 200) {      
              this.chip = 0;        
              this.total = 410;  
              this.products = res.data.result;
              this.admin = res.data.admin;
              //this.products = res.data.recordsets[0];
              if(this.products.length == 0)
                this.ExistVisits = true;
              else
              this.ExistVisits = false;
              var d1 = new Date();
              d1 = d1.getHours();
              for (let i = 0; i < this.products.length; i++) {
                var c = new Date(this.products[i].timedate.toString())
                var cc = c.getUTCHours();
                if(parseInt(d1) == parseInt(cc)){
                  if(this.products[i].IsLate == 1)
                    this.products[i].IsLate = 0;
                }                
                //console.log(cc);
               // console.log(this.products[i].timedate.toString());
               // console.log(cc);                
                if(this.products[i].PhoneNumber != null)
                  this.products[i].PhoneNumber = this.products[i].PhoneNumber.replace('0','+964');              
              this.showDetails.push(false);
              this.array_details.push(this.products[i].details);
              }  
              this.error = false;   
              this.$store.commit("setLoading", false);                                                             
            } else {
              this.error = true;
              this.$store.commit("setLoading", false);
            }
          })                         
      /*this.$http
        .get("/products", {
          params: this.options,
          paramsSerializer: this.$service.qsParams,
        })
        .then((response) => {
          this.products = response.data.data;
          let update = false;
          this.products.forEach((element, i) => {
            if (element.index == null) {
              element.index = i;
              update = true;
            }
          });
          if (update) {
            let ids = this.products.map((item, index) => {
              return { id: item.id, title: item.title, index: index };
            });
            this.updateIndex(ids);
          }

          this.total = response.data.meta.pagination.total;
          this.$store.commit("setLoading", false);
        });*/
        this.loading = false;
    },
    async updateIndex(products) {
      let data = {
        categoryId: this.options.filters.category.id.$eq,
        products,
      };
      // this.products.forEach((element) => {
      //   data.products.push({
      //     id: element.id,
      //     index: element.index,
      //   });
      // });
      await this.$http.post("/products/updateIndex", data);
    },
    openAddDialog() {
      this.$store.state.item = [];
      this.$root.$emit("fill-f");
      this.$store.state.dialog = true;
      this.$store.state.dialogType = "add";
    },
    openEditDialog(item) {
      this.$store.state.item = item;
      this.$root.$emit("fill-fields", item);
      this.$store.state.dialog = true;      
      this.$store.state.dialogType = "edit";
    },
    deleteItem(id, title) {
      title;
      this.$store.commit("setLoading", true);
      this.$http.put("/products/" + id, {
        data: {
          publishedAt: null,
        },
      });
      this.products = this.products.filter((item) => item.id != id);
      // this.getnews()
      this.$store.commit("setLoading", false);
    }
  },
  watch: {
    options: {
      handler() {
        this.getnews();
      },
      deep: true,
    },
  },

}; 

</script>
<style scoped>
.backend{
  background-color: #cad2bf
}
.wr{
  word-break: auto-phrase;
}
:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --green: #2b913c;
}
.btn_bootstrap{
  background-color: #187DA0;
}
.v-card__subtitle, .v-card__text, .v-card__title {
    padding: 5px;
}
.v-card__title {
    align-items: flex-start;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    word-break: break-all;
    justify-content: space-between;
}
.btn{
  width: 100%;
  display: flex;
  justify-content: center;  
}
.card1{
  margin: 10px;
}
.data-picker{
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.b_btn{
  margin-left: 10px;
  margin-right: 10px;
}
.telephone{
  direction: ltr;
  text-align: right;
}
.v-application .my-12 {
     margin-top: 0px !important; 
    margin-bottom: 0px !important; 
}

.lds-roller {
  /* change color here */
  color: #1c4c5b
}
.lds-roller,
.lds-roller div,
.lds-roller div:after {
  box-sizing: border-box;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7.2px;
  height: 7.2px;
  border-radius: 50%;
  background: currentColor;
  margin: -3.6px 0 0 -3.6px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 62.62742px;
  left: 62.62742px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 67.71281px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 70.90963px;
  left: 48.28221px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 70.90963px;
  left: 31.71779px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 67.71281px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 62.62742px;
  left: 17.37258px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12.28719px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
    @keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
    }
    .container {
      height: 100vh;      
      display: flex;
      justify-content: center;
      align-items: center;
      }
*{
    box-sizing: border-box;
}
body{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #050801;
    font-family: 'raleway',sans-serif;
    font-weight: bold;
}
.anim{    
    /*color: #03e9f4;*/
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.5s;
    overflow: hidden;
    margin-right: 50px;      
}
.anim div span{
    position: absolute;
    display: block;
}
.anim div span:nth-child(1){
    top: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg,transparent,#1e90ff);
    animation: animate1 2s linear infinite;
}
div {
  word-wrap: break-word;
}

@keyframes animate1{
    0%{
        left: -100%;
    }
    50%,100%{
        left: 100%;
    }
}
  
.anim div span:nth-child(2){
    top: -100%;
    right: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(180deg,transparent,#1e90ff);
    animation: animate2 2s linear infinite;
    animation-delay: 1s;
}
  
@keyframes animate2{
    0%{
        top: -100%;
    }
    50%,100%{
        top: 100%;
    }
}
  
  
.anim div span:nth-child(3){
    bottom: 0;
    right: 0;
    width:100%;
    height: 2px;
    background: linear-gradient(270deg,transparent,#1e90ff);
    animation: animate3 2s linear infinite;
}
@keyframes animate3{
    0%{
        right: -100%;
    }
    50%,100%{
        right: 100%;
    }
}
  
.anim div span:nth-child(4){
    bottom: -100%;
    left: 0;
    width: 2px;
    height: 100%;
    background: linear-gradient(360deg,transparent,#1e90ff);
    animation: animate4 2s linear infinite;
    animation-delay:1s;
}
@keyframes animate4{
    0%{
        bottom: -100%;
    }
    50%,100%{
        bottom: 100%;
    }
}
.blink_me {
  animation: blinker 2s linear infinite;
}
label {
	color: green;
	display: block;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
</style>