<template>
  <div>
    <v-dialog v-model="$store.state.dialog" persistent :overlay="false" max-width="1000px"
      transition="dialog-transition">
      <v-card flat>
        <v-card-title>
          <span class="headline">
            {{ $store.state.dialogType === "add" ? "اضافة" : "تعديل" }}
            جلسة
          </span>

          <v-spacer></v-spacer>
          <v-btn icon @click="$store.state.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-form v-model="valid" ref="form">
            <v-row v-if="!$store.state.loading">
              <v-col cols="12" md="6">
                <v-select v-if="$store.state.dialogType === 'add'"  outlined label="المستخدم" name="user" v-model="userId" :items="users" item-value="Id"
                  item-text="UserName" :rules="[$rules.required()]"></v-select>
                  <v-select v-else  outlined label="المستخدم" name="user" v-model="userId" :items="users" item-value="Id"
                  item-text="UserName" :rules="[$rules.required()]" disabled></v-select>
              </v-col>

              <v-col cols="12" md="6">
                <v-select v-if="$store.state.dialogType === 'add'"  outlined label="الشركة" :items="companies" name="company" item-value="Id"
                  item-text="ClientName" v-model="clientId" @input="logValue" :rules="[$rules.required()]"></v-select>
                  <v-select v-else outlined label="الشركة" :items="companies" name="company" item-value="Id"
                  item-text="ClientName" v-model="clientId" @input="logValue" :rules="[$rules.required()]" disabled></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-if="$store.state.dialogType === 'add'" outlined label="الفرع" name="branch" item-value="Id" item-text="BranchName" v-model="branchId"
                  :items="branches" :rules="[$rules.required()]"></v-select>
                  <v-text-field v-else outlined label="الفرع" name="branch" v-model="branchId"
                  :rules="[$rules.required()]" disabled></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-if="$store.state.dialogType === 'add'" outlined label="رقم الهاتف" name="NumberPhone" item-value="Id" item-text="PhoneNumber"
                  v-model="phoneId" :items="phones" :rules="[$rules.required()]"></v-select>
                  <v-text-field v-else outlined label="رقم الهاتف" name="NumberPhone" v-model="phoneId"
                  :rules="[$rules.required()]" disabled></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-if="$store.state.dialogType === 'add'"  outlined label="القناة" name="channel" item-value="Id" item-text="ChannelName"
                  v-model="channelId" :items="channels" :rules="[$rules.required()]"></v-select>
                  <v-select v-else outlined label="القناة" name="channel" item-value="Id" item-text="ChannelName"
                  v-model="channelId" :items="channels" :rules="[$rules.required()]" disabled></v-select>
              </v-col>
              <!--<v-col cols="12" md="6">
                <v-text-field outlined label="مدة الجلسة" name="time" v-model="time"
                  :rules="[$rules.required()]"></v-text-field>
              </v-col>-->
              <v-col cols="12" md="6">
                <v-text-field v-if="$store.state.dialogType === 'add'"   outlined label="المهمة" name="task" v-model="task"
                  :rules="[$rules.required()]"></v-text-field>
                  <v-text-field v-else outlined label="المهمة" name="task" v-model="task"
                  :rules="[$rules.required()]" disabled></v-text-field>
              </v-col>              
              <v-col cols="12" md="6">
                <h2 class="adress">بداية الزيارة</h2>                
                  <v-time-picker v-model="startSession" mode="dateTime" is24hr >
                </v-time-picker>
              </v-col>
              <v-col cols="12" md="6">
                <h2 class="adress">نهاية الزيارة</h2>
                <v-time-picker v-model="finishSession" mode="dateTime" is24hr>
                </v-time-picker>
              </v-col>              
              <v-col cols="12" md="6">
                <v-textarea outlined label="التفاصيل" name="details" v-model="details"
                  :rules="[$rules.required()]" width ="500px"></v-textarea>
              </v-col>
              <v-col cols="12" md="6">
                <v-btn size="x-large" color="primary" @click="openAddDialog()"> {{ $store.state.dialogType === "add" ? "اضافة" : "تعديل" }}</v-btn>
              </v-col>
            </v-row>
            <div v-else class="d-flex justify-center mt-10 pt-10">
              <v-progress-circular indeterminate size="60"></v-progress-circular>
            </div>
          </v-form>
        </v-card-text>        
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// import moment from 'moment'
export default {
  data() {
    return {
      date: new Date(),
      file: null,
      user: "",
      clientId: "",
      company: "",
      phones: [],
      channelId: "",
      companies: [],
      branches: [],
      channels: [],
      users: [],
      startSession:"",
      finishSession:"",
      details: "",
      task: "",
      userId: "",
      branchId: "",
      phoneId:"",
      id:"",
      inputs: {
        data: {
          title: "",
          price: "",
          category: null,
          status: "enabled",
          content: "",
          currency: this.$store.state.resturant.currency,
          additons: [],
          design: null,
          resturant: this.$store.state.resturant.id,
          timed: false,
          from: null,
          to: null,
          discount: null,
          discount_type: null,
          erp_code: "",
        },
      },
      loading: false,
      showDesc: false,
      categories: [],
      designs: [],
      additons: [],
      discountTypes: [
        {
          text: 'نقصان',
          value: 'money'
        },
        {
          text: 'بالمئة',
          value: 'percentage'
        }
      ],
      to: null,
      from: null,
      valid: false,
      text: "",
      isEdit: false,
    };
  },
  methods: {
    async openAddDialog() {
      console.log("userId : " + this.userId);
      console.log("ClientId : " + this.clientId);
      console.log("BranchId : " + this.branchId);
      console.log("PhoneId : " + this.phoneId);
      console.log("ChannelId : " + this.channelId);
      console.log("statSession : " + this.startSession);
      console.log("finishSession : " + this.finishSession);
      console.log("task : " + this.task);
      console.log("details : " + this.details);
      console.log("id : " + this.id);
      this.$store.commit("setLoading", true);
     /* let dataR = {
        params:{
          id:this.id,
          statSession:this.startSession,
          finishSession:this.finishSession,
          task:this.task,
          details:this.details
        }
      };*/
        await this.$http.get("/report",{
          params: {
            id:this.id,
            startSession:this.startSession,
            finishSession:this.finishSession,
            task:this.task,
            details:this.details
          }})
          .then(async (res) => {             
            if (res.status == 200) {              
              console.log(res.data);      
              this.$store.commit("setLoading", false);                                                       
            } else {
              this.error = true;
              this.$store.commit("setLoading", false);
            }
          })
          this.$store.state.dialog = false;
          this.$root.$emit("refresh-data");
    },
    addAdditons() {
      this.additons.push({
        text: "",
        erp_code: "",
        price: 0,
        priced: false,
      });
      this.additons.reverse();
    },
    async logValue() {
      this.$store.commit("setLoading", true);
      await this.$http.get("/branches", {
        params: {
          ClientId: this.clientId
        }
      })
        .then(async (res) => {
          if (res.status == 200) {
            this.total = 410;
            this.branches = res.data.recordsets[0];
            console.log("ClientId : " + this.clientId);
            this.error = false;
          } else {
            this.error = true;
          }
        })
      await this.$http.get("/phones", {
        params: {
          ClientId: this.clientId
        }
      })
        .then(async (res) => {
          if (res.status == 200) {
            this.total = 410;
            this.phones = res.data.recordsets[0];
            console.log("ClientId : " + this.clientId);
            this.error = false;
            this.$store.commit("setLoading", false);
          } else {
            this.error = true;
            this.$store.commit("setLoading", false);
          }
        })
    },
    removeAdditons(index) {
      this.additons.splice(index, 1);
    },
    async add() {
      this.$store.commit("setLoading", true);
      this.inputs.data.additons = this.additons;
      if (this.inputs.data.timed) {
        this.inputs.data.from = this.from + ":00.000";
        this.inputs.data.to = this.to + ":00.000";
      }
      if (this.file != null) {
        let res = await this.$service.uploadFile(this.file);
        this.inputs.data.photo = res.id;
      }
      if (this.$store.state.dialogType == "edit") {
        await this.$http.put(
          "/products/" + this.$store.state.item.id,
          this.inputs
        );
      } else {
        await this.$http.post("/products", this.inputs);
      }

      this.$store.commit("setLoading", false);
      this.$root.$emit("refresh");
      this.$store.state.dialog = false;
    },
    async getnews() {
      console.log(this.company);
    }
  },
  async created() {
    this.getnews();
    this.$root.$on("refresh", () => {
      console.log("refresh");
    });
  },
  async mounted() {
    /*this.$store.commit("setLoading", true);
    await this.$http.get("/clients")
      .then(async (res) => {
        if (res.status == 200) {
          this.total = 410;
          this.companies = res.data.recordsets[0];
          this.error = false;
        } else {
          this.error = true;
        }
      })
    await this.$http.get("/users")
      .then(async (res) => {
        if (res.status == 200) {
          this.total = 410;
          this.users = res.data.recordsets[0];
          this.error = false;

        } else {
          this.error = true;
        }
      })
    await this.$http.get("/channels")
      .then(async (res) => {
        if (res.status == 200) {
          this.total = 410;
          this.channels = res.data.recordsets[0];
          this.error = false;
          this.$store.commit("setLoading", false);
        } else {
          this.error = true;
          this.$store.commit("setLoading", false);
        }
      })
    this.$root.$on("fill-fields", (data) => {
      console.log( data);
      this.channelId = data.ChannelId;
      this.clientId = data.ClientId;
      this.userId = data.UserId;
      this.branchId = data.BranchName;
      this.phoneId = data.PhoneNumber;
      this.id = data.Id;
      this.task = data.task;
      this.details = data.details;
    });
    this.$root.$on("fill-f", () => {      
      this.channelId = "";
      this.clientId = "";
      this.userId = "";
      this.branchId = "";
      this.phoneId = "";
    });*/
  },
  watch: {
    options: {
      handler() {
        console.log(this.$company);
      },
      deep: true
    },
    "$store.state.dialog"(val) {
      if (val == false) {
        this.showDesc = false;
        this.inputs.data = {
          title: "",
          price: "",
          category: null,
          status: "",
          content: "",
          currency: "",
          discount: null,
          discount_type: null,
          design: null,
          resturant: this.$store.state.resturant.id,
        };
        this.additons = [];
        this.file = null;
      }
    },
  },
};
</script>
<style scoped>
.adress{
  margin-bottom: 10px;
}
</style>
